.DailyText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #496b84;
  background-image: url(/images/air.jpg);
  background-size: cover;
  background-position: center;
  border-radius: var(--border-radius-1);
  height: 100%;
  color: #fff;
  text-align: left;
  padding: var(--spacing-3);
  gap: var(--spacing-2);
  overflow: hidden;
  position: relative;
  isolation: isolate;

  img {
    position: absolute;
    inset: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  p {
    font-weight: 600;
  }

  span {
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 2.8rem;
    border-radius: 4rem;
    padding: 0 var(--spacing-2);
    box-shadow: var(--box-shadow-2);
    color: var(--color-primary-1);
    font-size: 1.4rem;
    font-weight: 600;
    padding-bottom: 1px;
    margin: 0 auto;
  }
}
