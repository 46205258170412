@import '../../styles/variables';
@import '../../styles/util';

.Item {
  text-align: left;
  text-decoration: none;
  display: grid;
  grid-template-columns: 108px auto;
  background-color: #fff;
  border-radius: var(--border-radius-1);
  box-shadow: var(--box-shadow-1);
  overflow: hidden;
  align-items: center;
  height: 100%;

  picture {
    display: block;
    width: 100%;
    background-color: #aaa;
    height: 100%;
    aspect-ratio: 1 / 1;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.ItemBody {
  display: flex;
  flex-direction: column;
  flex-flow: column-reverse;
  padding: 0.8rem 1.2rem;
  gap: 0.2rem;

  h3 {
    color: var(--color-dark-1);
    font-weight: 400;
    @include line-clamp(1);
    padding-top: 0.4rem;
  }

  time {
    color: var(--color-dark-1);
    font-size: 1.2rem;
    font-weight: 400;
  }

  strong {
    font-size: 1.4rem;
    font-weight: 600;
  }
}

@media only screen and (min-width: 816px) {
  .Item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    picture,
    img {
      aspect-ratio: 16 / 9;
      height: auto;
    }
  }

  .ItemBody {
    padding: 1.4rem 1.2rem;

    h3 {
      @include line-clamp(2);
    }
  }
}
