@import '../../styles/variables';
@import '../../styles/util';

.Item {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  text-align: start;
  box-shadow: var(--box-shadow-3);
  border-radius: var(--border-radius-1);
  overflow: hidden;
}

.Program {
  position: relative;

  h2 {
    text-transform: uppercase;

    a {
      color: #fff;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
    }
  }

  picture {
    background-color: #eee;
    aspect-ratio: 16 / 9;
    display: block;
  }

  img {
    aspect-ratio: 16 / 9;
    width: 100%;
  }

  > div {
    isolation: isolate;
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--spacing-2);

    @media only screen and (width >= $tablet-landscape) {
      padding: var(--spacing-3);
    }
  }

  button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  strong {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.8rem;
    display: flex;
    align-items: center;
    gap: 0.6rem;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  }
}

.Icon {
  width: 1.4rem;
  height: 1.4rem;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 1rem;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 2px;
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.5);
  }

  &::after {
    content: '';
    position: absolute;
    inset: 4px;
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 1);
  }
}

.BroadcastInfo {
  min-height: 8rem;
  position: relative;

  &.cycle {
    & > * {
      transform: translate(0, 0);
      transition:
        transform 300ms ease-in-out,
        opacity 300ms ease-in-out;
    }
  }

  &.cycle0 {
    & > *:first-child {
      transform: translate(0, 0);
      opacity: 1;
    }

    & > *:last-child {
      transform: translate(-4rem, 0);
      opacity: 0;
    }
  }

  &.cycle1 {
    & > *:first-child {
      transform: translate(-4rem, 0);
      opacity: 0;
    }

    & > *:last-child {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
}

.NowPlayingItem {
  --height: 8rem;
  position: absolute;
  inset: 0;
  min-height: var(--height);
  background-color: #fff;
  display: grid;
  grid-template-columns: var(--height) auto;
  align-items: center;

  img {
    height: var(--height);
    width: var(--width);
  }

  h3 {
    font-size: 1.4rem;
    text-transform: uppercase;
  }

  p {
    font-size: 1.8rem;
    line-height: 2.2rem;
    @include line-clamp(1);
  }

  div {
    padding: 0 var(--spacing-2);
  }
}

.ProgramItem {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--spacing-2);
  min-height: 8rem;
  font-size: 1.8rem;
  line-height: 2.7rem;
  background-color: #fff;

  div {
    display: flex;
    align-items: center;
    gap: var(--spacing-1);
    flex-wrap: wrap;

    & > * {
      font-size: 1.8rem;
      line-height: 2.6rem;
      padding-right: var(--spacing-1);
    }
  }

  p {
    @include line-clamp(1);
  }
}

@keyframes move {
  0%,
  80% {
    opacity: 1;
    transform: translate(0, 0);
  }

  100% {
    opacity: 0;
    transform: translate(-8rem, 0);
  }
}
