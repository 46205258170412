@import '../../styles/variables';

.FeaturedItems {
  display: grid;
  padding: 0 var(--spacing-2);
  gap: var(--spacing-3);
  max-width: var(--max-width-page);
  margin: 0 auto;

  > div {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-3);
  }
}

@media only screen and (width >= $tablet-portrait) {
  .FeaturedItems {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: 0 var(--spacing-3) 0 var(--spacing-3);
    gap: var(--spacing-3);

    > div {
      gap: var(--spacing-3);
    }

    & > :first-child {
      grid-column: 1 / 8;
    }

    & > :last-child {
      grid-column: 8 / 13;
    }
  }
}

@media only screen and (width > $tablet-landscape) {
  .FeaturedItems {
    display: grid;
    gap: var(--spacing-4);

    > div {
      gap: var(--spacing-4);
    }

    & > :first-child {
      grid-column: 2 / 8;
    }

    & > :last-child {
      grid-column: 8 / 12;
    }
  }
}

@media only screen and (width > $size-that-fits-hover-menu) {
  .FeaturedItems {
    padding-top: var(--spacing-4);
  }
}
