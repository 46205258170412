.Item {
  text-align: center;
  display: grid;
  gap: 0.4rem;
  align-content: start;

  > div {
    aspect-ratio: 1 / 1;
    position: relative;
    display: grid;
    align-items: flex-end;
    justify-content: center;
    box-shadow: var(--box-shadow-1);
    border-radius: var(--border-radius-1);

    img {
      transition: scale 300ms ease-in-out;
      scale: 1;
      width: 100%;
      height: 100%;
    }

    &:hover img {
      scale: 1.05;
    }

    a {
      background-color: #ddd;
      border-radius: var(--border-radius-1);
      overflow: hidden;
      position: absolute;
      inset: 0;
      isolation: isolate;
    }

    time {
      font-size: 1.4rem;
      font-weight: 400;
      color: var(--color-primary-1);
      background-color: #fff;
      border-radius: 2rem;
      box-shadow: var(--box-shadow-2);
      padding: 0.1rem 1.2rem;
      transform: translateY(50%);
    }

    button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  h3 {
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin-top: 1.6rem;
    hyphens: auto;

    a {
      text-decoration: none;
      color: var(--color-dark-1);
    }
  }

  p {
    font-size: 1.4rem;
  }
}
