.Item {
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  img {
    transition: scale 300ms ease-in-out;
    scale: 1;
    aspect-ratio: 1 / 1;
    height: auto;
    width: 100%;
  }

  > a {
    display: block;
    overflow: hidden;
    isolation: isolate;
    background-color: #ccc;
    box-shadow: var(--box-shadow-1);
    border-radius: var(--border-radius-1);

    &:hover img {
      scale: 1.05;
    }

    &:focus-visible img {
      scale: 1.05;
    }
  }

  strong {
    font-size: 1.5rem;
    font-weight: 400;
    background-color: #fff;
    border-radius: 2rem;
    color: var(--color-primary-1);
    box-shadow: var(--box-shadow-2);
    padding: 0.2rem 1.6rem 0.3rem 1.6rem;
    transform: translateY(-50%);
    margin: 0 auto;
  }

  h3 {
    font-size: 1.8rem;
  }
}
