.Banner {
  --angle: 225deg;
  --color-1: hsl(181, 48%, 50%);
  --color-2: hsl(181, 100%, 29%);
  --alpha: 0.1;
  --shadow: hsl(182, 100%, 15%); // color 2 darkened 50%

  position: relative;
  overflow: hidden;
  background-color: var(--color-1);
  border-radius: var(--border-radius-1);
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  min-height: 176px;
  position: relative;
  gap: var(--spacing-2);
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(
      var(--angle, 225deg),
      rgba(255, 255, 255, var(--alpha)) 0%,
      rgba(255, 255, 255, var(--alpha)) 50%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(at top left, var(--color-1), var(--color-2));

  &.theme-orange {
    --color-1: hsl(40, 100%, 48%);
    --color-2: hsl(23, 98%, 46%);
    --shadow: hsla(23, 100%, 23%, 0.4);
  }

  &.theme-teal {
    --color-1: hsl(181, 48%, 50%);
    --color-2: hsl(181, 100%, 29%);
    --shadow: hsla(182, 100%, 15%, 0.4);
  }

  &.theme-blue {
    --color-1: hsl(195, 90%, 19%);
    --color-2: hsl(197, 100%, 16%);
    --shadow: hsla(197, 100%, 8%, 0.4);
    --alpha: 0.06;
  }

  picture {
    position: absolute;
    inset: 0;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    padding: var(--spacing-3) var(--spacing-2);
    gap: var(--spacing-2);
    grid-column: 1 / 7;
    position: relative;

    > a {
      display: flex;
      align-items: center;
      background-color: var(--color-2);
      height: 4rem;
      border-radius: 4rem;
      line-height: 2rem;
      padding: 0 var(--spacing-2);
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
      padding-bottom: 2px;
      color: #fff;
    }
  }

  &.align-left div {
    text-align: left;
    align-items: start;
  }

  &.align-center div {
    text-align: center;
    align-items: center;
  }

  &.align-right div {
    text-align: right;
    align-items: end;
  }

  p {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  .Tag {
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 2.8rem;
    border-radius: 4rem;
    padding: 0 var(--spacing-2);
    box-shadow: var(--box-shadow-2);
    color: var(--color-primary-1);
    font-size: 1.4rem;
    font-weight: 600;
    padding-bottom: 1px;
  }

  .BannerText {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: var(--spacing-2);
    width: 100%;

    & > * {
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    }

    h1 {
      font-size: 4.2rem;
      line-height: 4rem;
      font-weight: 900;
    }

    h2 {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }

    h3 {
      font-size: 2rem;
      line-height: 2.4rem;
    }

    // The Jiffy font contains some additional internal whitespace,
    // so we decrease the line-height a bit to account for it.
    h1[style*='Jiffy'],
    h1 > span[style*='Jiffy'] {
      line-height: 2.4rem;
    }

    h2[style*='Jiffy'],
    h2 > span[style*='Jiffy'] {
      line-height: 2rem;
    }
  }
}
