.Ads {
  display: grid;
  grid-template-areas: 'center';
  align-items: center;
  justify-content: center;
  height: 100%;

  a {
    grid-area: center;
    opacity: 0;
    pointer-events: none;

    &[aria-hidden='false'] {
      opacity: 1;
      pointer-events: all;
    }
  }
}
